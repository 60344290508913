@import url("https://fonts.googleapis.com/css2?family=Beau+Rivage&family=Fredoka+One&family=Montserrat:wght@100;200;300;400;700&family=Roboto:wght@100;300;400;500;900&family=Waterfall&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Beau+Rivage&display=swap");
html,
body {
  margin: 0;
  padding: 0;
  overflow-x: initial !important;
}
h1 {
  padding-top: 20px;
  font-family: "Montserrat", sans-serif;
  font-size: 16px;
  color: #3d3d3d;
}
a {
  text-decoration: none;
  color:black;
}
p {
  font-family: "Montserrat", sans-serif;
  font-size: 14px;
  text-align: justify;
}
* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Montserrat", sans-serif;
}
body {
  margin: 0;
  padding: 0;
}

.Collapsible__trigger {
  cursor: pointer;
  display: block;
  max-width: 700px;
  font-weight: 400;
  text-decoration: none;
  color: #333333;
  position: relative;
  border: 1px solid white;
  padding: 10px;
  background: #8a8a8a;
  color: #ffffff;
}
.Collapsible__trigger:after {
  content: "+";
  position: absolute;
  right: 10px;
  top: 2px;
  font-size: 30px;
  display: block;

  transition: transform 300ms;
}
.Collapsible__trigger.is-open:after {
  transform: rotateZ(180deg);
  top: 4px;
  content: "-";
}
.Collapsible__trigger.is-disabled {
  opacity: 0.5;
  background-color: grey;
}

nav a:hover {
  background: rgb(166, 175, 255);
  transition: all ease-in-out 0.2s;
}
